











import logoSrc from "@/assets/logoSrc";
import LoginForm from "@/components/LoginForm.vue";
import Vue from "vue";

export default Vue.extend({
  components: { LoginForm },
  data: () => ({
    logoSrc,
  }),
});
