














































import Vue from "vue";
import { required, min } from "vee-validate/dist/rules";
import { extend, setInteractionMode } from "vee-validate";
import {
  hasMachineViewPermission,
  hasUserViewPermission,
} from "@/utils/UserPermissions";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} non può essere vuoto.",
});

extend("min", {
  ...min,
  message: "{_field_} deve contenere almeno {length} caratteri.",
});

export default Vue.extend({
  name: "LoginForm",

  data: () => ({
    username: "",
    password: "",
  }),

  methods: {
    async submit(): Promise<void> {
      if (!["success", "loading"].includes(this.$store.getters.authStatus)) {
        // @ts-expect-error wrong type
        const valid = await this.$refs.observer.validate();
        if (valid) {
          const payload = { username: this.username, password: this.password };

          this.$store
            .dispatch("login", payload)
            .then(() => {
              const userRoles = this.$store.getters.userRoles;
              if (hasMachineViewPermission(userRoles)) {
                this.$router.replace("/machines");
              } else if (hasUserViewPermission(userRoles)) {
                this.$router.replace("/users");
              } else {
                this.$router.replace("/blank");
              }
            })
            .catch((error: Error) => {
              this.$store.commit("mutation_open_snackbar_error", error.message);
            });
        }
      }
    },
  },
});
